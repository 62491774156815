import 'font-awesome/css/font-awesome.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import '../css/service.css';

function Bannersservice() {
    return (
        <>
<div className='head-isp-serviceban '>
    <p className='head-isp-about'>Services</p>
    <p className='head-isp-para'>Connect to a fast and reliable internet connection anywhere.</p>
</div>
            
        </>

    );
}

export default Bannersservice;
