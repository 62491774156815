import 'font-awesome/css/font-awesome.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import '../css/DwBlog.css';

function Bannerblog() {
    return (
        <>
<div className=' head-isp-abou-blog'>
    <p className='head-isp-about'>Blogs</p>
    {/* <p className='head-isp-para'>Discover Valuable Insights on Fast & Reliable Internet in Our Blogs.</p> */}
</div>
            
        </>

    );
}

export default Bannerblog;
