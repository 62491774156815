import 'font-awesome/css/font-awesome.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import '../css/mainpackage.css';

function Bannerspackmain() {
    return (
        <>
<div className='head-isp-serviceban '>
    <h1 className='head-isp-about'>Business Broadband</h1>
    <p className='head-isp-para'>1 Public IP</p>
    {/* <p className='head-isp-para'>Throughput Commitment:- 85% Of Subscribed Bandwidth</p> */}
    <p className='head-isp-para'>IPV6 Ready</p>

</div>
            
        </>

    );
}

export default Bannerspackmain;
