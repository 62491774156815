import 'font-awesome/css/font-awesome.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import '../css/Packages.css';

function Bannerpac() {
    return (
        <>
<div className=' head-isp-abou-pacs'>

    <h1 className='head-isp-about'>Broadband Internet Service</h1>
    <h2 className='head-isp-para'>Unlock Seamless <a href='/internet-service-provider'>Broadband Internet</a> Speeds for Your Digital Lifestyle.</h2>
</div>
            
        </>

    );
}

export default Bannerpac;
