import Fournotfour from './404page/four';
function Four() {

    return (
        <>
            <div className='ispmainservice'>
              
     <Fournotfour />

            </div>
          
        </>
    );
}

export default Four;